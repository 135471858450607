.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-family: Arial, sans-serif;
  font-size: 24px;
  background: #ffffff;
  text-align: center;
}

.loader-logo {
  width: 200px;
  height: auto;
  margin-bottom: 20px;
}

.loader-text {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 30px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #333;
}

.spinner-container {
  position: relative;
  width: 50px;
  height: 50px;
}

.spinner {
  position: absolute;
  width: 50px;
  height: 50px;
  border: 5px solid transparent;
  border-top: 5px solid #333;
  border-right: 5px solid #333;
  border-radius: 50%;
  animation: spin 0.6s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}