@use '../styles.scss' as common;

.AosSubscriptionContainer {
  margin: 0 auto;
  padding: 1.5rem;
  text-align: justify;
  color: common.$text-colour;
  font-size: common.$body-font-size;
  line-height: 1.5;

  p {
    margin-bottom: 1.5rem;
  }
}